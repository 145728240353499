import { Route } from "react-router-dom";
// import EventsLanding from "../pages/landing/eventsLanding";
// import EventDetailLanding from "../pages/landing/eventDetailLanding";
// import Idcard from "../pages/landing/idcard";
import React from "react";
import PublicStream from "../pages/PublicStream";

const CustomPublicRoute = () => [
  <Route
    key="purchase-stream"
    path="/purchase-stream"
    element={<PublicStream />}
  />,
  // <Route key="event-details" path="/events/:slug" element={<EventDetailLanding />} />,
  // <Route key="id-card" path="/my-id-card/:event/:slug" element={<Idcard />} />, // Add the custom redirect route here
];
export default CustomPublicRoute;
