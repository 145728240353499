import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getData } from "../../../backend/api";
import { Button, Cards, ImgBox, SelectBox, TagBox, TagItem, TagList, TagTitle } from "./styles";
import { DownIcon, GetIcon } from "../../../icons";
import { useTranslation } from "react-i18next";
import { addSelectObject } from "../../../store/actions/select";
import Search from "../search";
import { getValue } from "../list/functions";
import { Checkbox, ElementContainer } from "../elements";
import { noimage } from "../../../images";
import { RowContainer } from "../../styles/containers/styles";
import InfoBoxItem from "../input/info";
import CustomLabel from "../input/label";
import ErrorLabel from "../input/error";
import Footnote from "../input/footnote";
import SelectableCard from "../input/SelectableCard";

function CustomSelect(props) {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [updateValue, setUpdateValue] = useState({});
  const [defaultValue] = useState(props.default);
  const [filter] = useState(props.filter ?? {});
  const [selectedId, setSelectedId] = useState(props.value);
  const [initialized, setInitialized] = useState(false);
  const [selectedValue, setSelectedValue] = useState(props.placeholder);
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectType] = useState(props.radioButton ? "radio" : props.selectType ?? "dropdown");
  const selectData = useSelector((state) => state.select[props.selectApi]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toggleOptions = (event) => {
    event.stopPropagation();
    setOptionsVisible(!optionsVisible);
  };
  const [searchValue, setSearchValue] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const handleChange = (event) => {
    event.stopPropagation();
    if (props.apiSearch) {
      fetchData(props.updateValue, true, "", event.target.value.toString());
      setSearchKey(event.target.value);
    } else {
      setSearchValue(event.target.value);
      const filteredOptions = options.filter((option) => option.value?.toLowerCase().includes(event.target.value.toString().toLowerCase()));
      setFilteredOptions(filteredOptions);
      if (event.target.value.toString() === "") {
        setFilteredOptions([]);
      }
    }
    // clearTimeout(searchTimeoutRef.current);
  };

  const fetchData = useCallback(
    async (item = {}, force = false, name = "", searchKey = "", params = {}) => {
      if (force && props.apiType === "API") {
        const optionHandler = (data) => {
          setOptions(data);
          setInitialized(true);
          try {
            const selected = data.filter((itemValue) => itemValue.id === selectedId)[0].value;
            setSelectedValue(selected ? selected : props.placeholder);
          } catch {}
        };
        await getData({ ...item, searchKey, limit: props.apiSearch ? 20 : 0, ...params, ...filter }, `${props.selectApi}`)
          .then((response) => {
            if (response.status === 200) {
              optionHandler(response.data);
              dispatch(addSelectObject(response.data, props.selectApi));
            } else if (response.status === 404) {
              setInitialized(false);
            } else {
              setInitialized(false);
            }
          })
          .catch((error) => {
            setInitialized(false);
          });
      } else if (props.apiType === "API") {
        const optionHandler = (data) => {
          setOptions(data);
          setInitialized(true);
          try {
            const selected = data.filter((itemValue) => itemValue.id === selectedId)[0].value;
            setSelectedValue(selected ? selected : props.placeholder);
          } catch {}
        };
        if (selectData) {
          optionHandler(selectData);
        } else {
          if (initialized) {
          } else {
            await getData({ ...item, ...filter }, `${props.selectApi}`)
              .then((response) => {
                if (response.status === 200) {
                  optionHandler(props.selectApi);
                  dispatch(addSelectObject(response.data, props.selectApi));
                } else if (response.status === 404) {
                  setInitialized(false);
                } else {
                  setInitialized(false);
                }
              })
              .catch((error) => {
                setInitialized(false);
              });
          }
        }
      } else if (props.apiType === "CSV") {
        const options = props.selectApi.split(",").map((item) => {
          return {
            id: item.trim(),
            value: item.trim().charAt(0).toUpperCase() + item.trim().slice(1),
          };
        });

        setOptions(options);
        setInitialized(true);
        try {
          const selected = options.filter((item) => item.id === selectedId)[0].value;
          setSelectedValue(selected ? selected : props.placeholder);
        } catch {}
      } else if (props.apiType === "JSON") {
        const options = props.selectApi;
        setOptions(options);
        setInitialized(true);
        try {
          const selected = options.filter((item) => item.id === selectedId)[0].value;
          setSelectedValue(selected ? selected : props.placeholder);
        } catch {}
      }
    },
    [props.apiType, props.selectApi, filter, props.placeholder, props.apiSearch, initialized, selectedId, selectData, dispatch]
  );
  const [prevValues, setPrevValues] = useState({});
  useEffect(() => {
    let values = {};
    props.params?.forEach((item) => {
      values[item.name] = item.value;
    });

    // Check if the current values are different from the previous values
    const isValuesChanged = JSON.stringify(values) !== JSON.stringify(prevValues);
    // If values have changed, save them and fetch data
    if (Object.keys(values).length > 0 && isValuesChanged) {
      setPrevValues(values);
      fetchData(values, true);
    } else {
      fetchData(values);
    }
  }, [props.selectApi, props.params, fetchData, prevValues]);

  const selectRef = useRef(null);
  useEffect(() => {
    if (props.updateOn) {
      const isObjectEqual = (obj1, obj2) => {
        const keys1 = Object.keys(obj1 ?? {});
        const keys2 = Object.keys(obj2 ?? {});
        // console.log("Keys:",keys1, keys2);
        if (keys1.length !== keys2.length) {
          return false;
        }

        for (let key of keys1) {
          if (obj1[key] !== obj2[key]) {
            return false;
          }
        }

        return true;
      };
      const equal = isObjectEqual(updateValue, props.updateValue);
      if (!equal) {
        setUpdateValue(props.updateValue);
        let values = {};
        props.params?.forEach((item) => {
          if (!item.value) {
            item.value = props.formValues?.[item.name] ?? "";
          }
          values[item.name] = item.value;
        });
        fetchData(props.updateValue, true, props.updateOn, "", values);
      }
    }
  }, [props.updateValue, updateValue, fetchData, props.updateOn, props.params, props.formValues]);
  useEffect(() => {
    function handleClick(event) {
      // Check if the click is outside the select component
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        // If the component is not a listBox, close the options
        if (!props.listBox) {
          setOptionsVisible(false);
        } else {
          // If it is a listBox, keep the options visible
          setOptionsVisible(true);
          setInitialized(true);
        }
      }
    }

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [props.listBox, props.customClass]);
  // Calculate the position of the dropdown
  const calculateDropdownPosition = () => {
    if (selectRef.current) {
      const rect = selectRef.current.getBoundingClientRect();
      const dropdownHeight = 200; // Example height of the dropdown
      const spaceBelow = window.innerHeight - rect.bottom;
      const spaceAbove = rect.top;

      return spaceBelow >= dropdownHeight ? "down" : spaceAbove >= dropdownHeight ? "up" : "down";
    }
    return "down"; // Default to down if unable to calculate
  };

  const dropdownPosition = calculateDropdownPosition();

  const renderRadioOptions = () => (
    <ElementContainer ref={selectRef} className={`column box ${props.customClass} ${props.dynamicClass}`}>
      <InfoBoxItem info={props.info} />
      {(props.showLabel ?? true) && <CustomLabel label={props.label} required={props.required} sublabel={props.sublabel} error={props.error ?? ""} />}
      <ElementContainer ref={selectRef} className="left">
        <InfoBoxItem info={props.info} />
        {options.length > 0
          ? (searchValue.length > 0 ? filteredOptions : options).map((option) => {
              const isSelected = option.id.toString() === selectedId.toString();
              // console.log("props.value:", props.value, ",option.value:", option.value.toString(), ",selectedId:", selectedId.toString(), ",option.id:", option.id, ",isSelected:", isSelected);
              return (
                <Checkbox
                  isDisabled={props.disabled}
                  key={option.id}
                  align="left"
                  customClass="round"
                  label={props.displayValue ? option[props.displayValue] : option.value}
                  onChange={(event) => {
                    const listBox = props.listBox ?? false;
                    if (!listBox) {
                      toggleOptions(event);
                      if (selectedId === option.id) {
                        props.onSelect({ id: defaultValue, value: props.placeholder }, props.id, props.type);
                        setSelectedValue(props.placeholder);
                        setSelectedId(defaultValue);
                      } else {
                        props.onSelect(option, props.id, props.type);
                        setSelectedValue(option.value);
                        setSelectedId(option.id);
                      }
                    } else {
                      props.onSelect(option, props.id, props.type);
                      setSelectedValue(option.value);
                      setSelectedId(option.id);
                    }
                  }}
                  value={isSelected}
                ></Checkbox>
              );
            })
          : null}
      </ElementContainer>
      <Footnote {...props} />
      <ErrorLabel error={props.error} info={props.info} />
    </ElementContainer>
  );

  const renderDropdownOptions = () => (
    <SelectBox key={props.key} theme={props.theme} className={`custom-select ${props.listBox ? "list-box" : ""} ${optionsVisible ? "open" : "close"} ${props.customClass} ${props.dynamicClass}`}>
      <InfoBoxItem customClass={"info-select"} info={props.info} />

      {(props.showLabel ?? true) && <CustomLabel label={props.label} required={props.required} sublabel={props.sublabel} error={props.error ?? ""} />}
      <button disabled={props.disabled} ref={selectRef} className={`${selectedId !== null && props.value?.length > 0 ? "has" : ""}`} onClick={toggleOptions}>
        {props.value?.toString().length === 0 ? <span>{t(props.placeholder)}</span> : <span>{selectedValue}</span>}
        {!props.disabled && <DownIcon className="down" />}
        <ul className={`options ${optionsVisible ? dropdownPosition : "hide"}`}>
          {((optionsVisible && initialized) || (initialized && props.listBox)) && (
            <>
              {props.search && !props.disableSearch && options.length > 8 && <Search active={true} key={`search-inside${props.key}`} className="select" title="Search" theme={props.theme} placeholder="Search" value={searchValue} onChange={handleChange} />}
              {props.apiSearch && <Search active={true} key="search-api-2" className={"select " + props.customClass} title="Search" theme={props.theme} placeholder={`Search ${props.placeholder}`} value={searchKey} onChange={handleChange} />}
              {options.length > 0 &&
                (searchValue.length > 0 ? filteredOptions : options).map((option) => (
                  <li
                    value={option.id === selectedId}
                    className={option.id === selectedId ? "selected" : ""}
                    key={option.id}
                    onClick={(event) => {
                      event.stopPropagation();
                      const listBox = props.listBox ?? false;
                      if (!listBox) {
                        toggleOptions(event);
                        if (selectedId === option.id) {
                          props.onSelect({ id: defaultValue, value: props.placeholder }, props.id, props.type);
                          setSelectedValue(props.placeholder);
                          setSelectedId(defaultValue);
                        } else {
                          props.onSelect(option, props.id, props.type);
                          setSelectedValue(option.value);
                          setSelectedId(option.id);
                        }
                      } else {
                        props.onSelect(option, props.id, props.type);
                        setSelectedValue(option.value);
                        setSelectedId(option.id);
                      }
                    }}
                  >
                    <span>
                      {props.tags ? (
                        <TagBox>
                          {props.iconImage && (
                            <ImgBox
                              onError={(e) => {
                                e.target.src = noimage; // Hide the image on error
                              }}
                              src={process.env.REACT_APP_CDN + (props.iconImage.collection.length > 0 ? option[props.iconImage.collection]?.[props.iconImage.item] ?? "" : option[props.iconImage.item])}
                            />
                          )}
                          <RowContainer>
                            <TagItem className="title">{props.displayValue ? option[props.displayValue] : option.value}</TagItem>
                            <TagList>
                              {props.tags.map((tag) => (
                                <React.Fragment key={tag.item}>
                                  <TagTitle>{`${tag.title.length ? tag.title + ": " : ""}${getValue(tag, tag.collection.length > 0 ? option[tag.collection]?.[tag.item] ?? "" : option[tag.item])}`}</TagTitle>
                                </React.Fragment>
                              ))}
                            </TagList>
                          </RowContainer>
                        </TagBox>
                      ) : props.displayValue ? (
                        option[props.displayValue]
                      ) : option.icon ? (
                        <React.Fragment>
                          <GetIcon icon={option.icon ?? "info"} />
                          <span>{option.value}</span>
                        </React.Fragment>
                      ) : (
                        option.value
                      )}
                      {/* {option.recipeVariants && (
                  <TagBox key={option._id}>
                    {props.iconImage && <ImgBox src={process.env.REACT_APP_CDN + (props.iconImage.collection.length > 0 ? option[props.iconImage.collection]?.[props.iconImage.item] ?? "" : option[props.iconImage.item])} />}
                    <Variants className="noMargin">
                      {option.recipeVariants.map((variant) => (
                        <Variant
                          key={variant._id}
                          onClick={() => {
                            props.selectVariant({ ...variant, recipe: option });
                          }}
                        >
                          <span>
                            <span>BHD</span>
                            <span className="price">{variant.price}</span>
                            <span className="offer">{variant.offerPrice}</span>
                          </span>
                          <span className="variant">{`${variant.variant} / ${variant.calories ?? 0} calories`}</span>
                        </Variant>
                      ))}
                    </Variants>
                  </TagBox>
                )} */}

                      {props.viewButton && (
                        <Button
                          onClick={(event) => {
                            event.stopPropagation();
                            props.viewButton?.callback(option);
                          }}
                        >
                          View Menu
                        </Button>
                      )}
                    </span>
                    {option.id === selectedId && <GetIcon className="tick" icon={"checked"}></GetIcon>}
                  </li>
                ))}
              {initialized && options.length === 0 && (
                <li
                  onClick={() => {
                    fetchData(props.updateValue, true, props.updateOn);
                  }}
                >
                  No Data Found. Click here to Refresh Data!
                </li>
              )}
            </>
          )}
        </ul>
      </button>
      <Footnote {...props} />
      <ErrorLabel error={props.error} info={props.info} />
    </SelectBox>
  );

  const renderTabs = () => (
    <div className="tabs">
      {options.map((option) => (
        <Button
          key={option.id}
          onClick={(event) => {
            const listBox = props.listBox ?? false;
            if (!listBox) {
              toggleOptions(event);
              if (selectedId === option.id) {
                props.onSelect({ id: defaultValue, value: props.placeholder }, props.id, props.type);
                setSelectedValue(props.placeholder);
                setSelectedId(defaultValue);
              } else {
                props.onSelect(option, props.id, props.type);
                setSelectedValue(option.value);
                setSelectedId(option.id);
              }
            } else {
              props.onSelect(option, props.id, props.type);
              setSelectedValue(option.value);
              setSelectedId(option.id);
            }
          }}
          className={selectedId === option.id ? "active" : ""}
        >
          {option.value}
        </Button>
      ))}
    </div>
  );

  const renderCard = () => (
    <SelectBox theme={props.theme} className={`custom-select ${optionsVisible ? "open" : "close"} ${props.customClass}  ${props.dynamicClass}`}>
      <InfoBoxItem className={"info-select"} info={props.info} />
      <CustomLabel label={props.label} required={props.required} sublabel={props.sublabel} error={props.error ?? ""} />
      <Cards>
        {options.length > 0
          ? (searchValue.length > 0 ? filteredOptions : options).map((option) => {
              const isSelected = option.id.toString() === selectedId?.toString();
              return (
                <SelectableCard
                  parentType={props.type}
                  id={props.id}
                  checked={isSelected}
                  type="radio"
                  onChange={() => {
                  
                    if (selectedId === option.id) {
                      props.onSelect({ id: defaultValue, value: props.placeholder }, props.id, props.type);
                      setSelectedValue(props.placeholder);
                      setSelectedId(defaultValue);
                    } else {
                      props.onSelect(option, props.id, props.type);
                      setSelectedValue(option.value);
                      setSelectedId(option.id);
                    }
                  }}
                  option={option}
                  key={option.id}
                />
              );
            })
          : null}
      </Cards>
    </SelectBox>
  );
  const renderSelectType = () => {
    switch (selectType) {
      case "radio":
        return renderRadioOptions();
      case "dropdown":
        return renderDropdownOptions();
      case "tabs":
        return renderTabs();
      case "card":
        return renderCard();
      default:
        return renderDropdownOptions(); // Fallback to dropdown
    }
  };

  return renderSelectType();
}

export default CustomSelect;
